function togglePasswordVisibility(elementId, self, event) {
  event.preventDefault()
  var passwordField = document.getElementById(elementId)
  var fieldType = passwordField.getAttribute('type')
  if (fieldType === 'password') {
    passwordField.setAttribute('type', 'text')
  } else {
    passwordField.setAttribute('type', 'password')
  }
  self.children[0].classList.toggle('d-none')
  self.children[1].classList.toggle('d-none')
}

window.togglePasswordVisibility = togglePasswordVisibility
